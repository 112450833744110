import * as React from "react"
import tw from "twin.macro"

const FooterContainer = tw.footer`bg-black mt-32 text-white py-16`
const FooterGrid = tw.div`flex flex-col md:grid md:grid-cols-2 max-w-screen-xl mx-auto`
const FooterColumn = tw.div`col-span-1 px-8`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterGrid>
        <FooterColumn tw="text-sm">
          <p tw="mb-2">&copy; 2015-2022 Long Rock Capital Ltd.</p>
          <p>Long Rock Capital Ltd. is a Limited Company registered in England and Wales</p>
        </FooterColumn>
        <FooterColumn tw="text-xs mt-8 md:mt-0">
          <p>The material contained in this web site does not constitute an offer for sale. Opinions expressed are
            subject to change at any time. The material is based upon information which is considered reliable, but no
            representation is made that it is accurate or complete, and should not be relied upon. Past performance is
            not an indication of future performance.</p>
        </FooterColumn>
      </FooterGrid>
    </FooterContainer>
  )
}

export default Footer