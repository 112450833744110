import * as React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import { HiChevronDown, HiChevronUp, HiMenuAlt3 } from "react-icons/hi"

import LRCLogo from "../images/lrc-logo.svg"

const HeaderFixedContainer = styled.div((props: { scrolled: boolean, open: boolean }) => [
  tw`fixed w-full p-0 md:px-4 z-30`,
  props.scrolled
    ? tw`bg-white text-black border-b border-gray-700`
    : (props.open ? tw`bg-white border-b border-gray-700 md:border-b-0 md:bg-transparent text-black md:text-white` : tw`text-white`)
])

const HeaderContainer = tw.header`max-w-screen-xl mx-auto relative`

const Content = tw.div`py-8 flex flex-col md:flex-row items-center justify-between text-center md:text-left`
const CompanyLogo = tw.div`text-xl font-bold`
const Nav = styled.nav((props: { visible: boolean }) => [
  props.visible ? tw`flex` : tw`hidden`,
  tw`w-full flex-col md:flex md:flex-row md:w-auto mt-8 md:mt-0`
])
const DropdownLink = tw(Link)`w-full block px-8 py-2 mt-2 text-sm hover:bg-gray-100`
const DropdownIcon = tw(HiChevronDown)`inline`
const DropdownIconOpen = tw(HiChevronUp)`inline`
const DropdownContainer = styled.div((props: { visible: boolean }) => [
  props.visible ? tw`block opacity-100` : tw`hidden opacity-0`,
  tw`relative md:absolute right-0 w-full mt-2 origin-top-right bg-white text-black px-2 py-2`,
  tw`w-full md:w-64 shadow-none md:shadow-lg`,
  tw`text-left`
])


export interface HeaderFixedProps {
  always: boolean
}

const HeaderFixed = (props: HeaderFixedProps) => {
  const [scroll, setScroll] = React.useState(0)
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const onScroll = (evt: Event) => {
      let target: HTMLElement & Document = evt.target as HTMLElement & Document
      setScroll(target.documentElement.scrollTop)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scroll])

  return (
    <HeaderFixedContainer scrolled={props.always || scroll > 10} open={open}>
      <HeaderContainer>
        <Content>
          <div tw="flex flex-row justify-between w-full md:w-auto px-4">
            <CompanyLogo>
              <Link to="/">
                <LRCLogo style={{height: "2.5rem", width: "13rem"}}/>
                <span tw="hidden">Long Rock Capital</span>
              </Link>
            </CompanyLogo>
            <button tw="focus:outline-none md:hidden" onClick={() => setOpen(!open)}>
              <HiMenuAlt3 />
            </button>
          </div>
          <Nav visible={open}>
            <Link tw="text-left font-semibold md:font-normal px-10" to="/contact">Contact</Link>
            <div>
              <div tw="relative hidden md:block">
                <button tw="focus:outline-none" onClick={() => setOpen(!open)}>
                  What we do {open ? <DropdownIconOpen /> : <DropdownIcon />}
                </button>
              </div>
              <DropdownContainer visible={open}>
                <div tw="block md:hidden px-8 font-semibold">
                  What we do
                </div>
                <DropdownLink to="/trading">Trading</DropdownLink>
                <DropdownLink to="/trustless">Trustless technology</DropdownLink>
                <DropdownLink to="/multichain">Multichain protocols</DropdownLink>
              </DropdownContainer>
            </div>
          </Nav>
        </Content>

      </HeaderContainer>
    </HeaderFixedContainer>
  )
}

export default HeaderFixed
