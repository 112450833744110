import * as React from "react"
import { Helmet } from "react-helmet"


export interface SEOProps {
  title: string
  description?: string
}

const SEO: React.FC<SEOProps> = (props: SEOProps) => {
  const { title, description } = props
  return (
    <Helmet title={title}>
      <html lang="en" />
      {description && <meta name="description" content={description} />}
    </Helmet>
  )
}

SEO.defaultProps = {
  description: undefined
}

export default SEO